import React, { useState,useEffect,useContext,useRef } from 'react';
import QRCode from 'qrcode.react';
import {UserContex} from '../App'
import {Link, useNavigate, useParams} from 'react-router-dom'
import SinglePage from './SinglePage';
import AdminHeader from '../components/AdminHeader';
import DatePicker from 'react-datepicker'
import { Document, Page, Text, View, StyleSheet , PDFViewer,PDFDownloadLink,Image} from '@react-pdf/renderer';

import html2canvas from 'html2canvas';
require('react-datepicker/dist/react-datepicker.css')

function MembershipDash() {
    const {state, dispatch}= useContext(UserContex)

    const history = useNavigate()
    const componentRef = useRef(null);

  const [inputText, setInputText] = useState('');
  const [qrCodeText, setQRCodeText] = useState('');
  const [Zonedata,setZoneData] = useState([])
  const [data,setData] = useState([])
  const [singleEvent,setSingleEvent] = useState(null)

  const [today,setToday] = useState(new Date())
  const[singleEarning,setSingleEarning]= useState(null)
  const[seller,setSeller]= useState(null)
  const[detail,setDetail]= useState(null)
  const[weeklyvote,setWeeklyVote]= useState(0)
  const[participants,setParticipants] = useState([])
  const {sellerid} = useParams()
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[zone,setZone]= useState("")
  const[code,setCode]= useState("")
  const[show,setShow]= useState("")
  const[status,setStatus]= useState("")
  const [addStock,setAddStock]= useState(false)
  const [addMember,setAddMember]= useState(false)
  const [addShow,setAddShow]= useState(false)
  const [url,setUrl] = useState("")  
  const[progress,setProgress]= useState(false)
  const [date,setDate] = useState(new Date())
  const[description,setDescription]=useState("")
  const [time,setTime]= useState("")
  const [row,setRow]= useState("")
  const [category,setCategory] = useState("")  
  const[price,setPrice]= useState("")
  const[venue,setVenue]=useState("")
  const[youtube,setYoutube]=useState("")
  const[active,setActive]=useState(0)
  const[inactive,setinactive]=useState(0)
  const[pending,setPending]=useState(0)
  const[live,setLive]=useState(false)
  const[paid,setPaid]=useState(false)
  const[membershipactivated,setMembershipActivated]=useState(false)
  const [location,setLocation] = useState([])  
  const [search,setSearch] = useState("")

  const [filteredParticipants, setfilteredParticipants] = useState([]);
  const [filter, setFilter] = useState('All');

  // var active=0


  console.log("PArticipants",participants)
  useEffect(  ()=>{
    setDetail( JSON.parse( localStorage.getItem("vendor")))


    fetch('/getsinglemembershipdash',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
    setSingleEvent(result.event)
    setMembershipActivated(result.event.vendor.membershipactivated)
    setParticipants(result.event.members)

    setfilteredParticipants(result.event.members)
    setActive(result.event.members.filter(member => member.status === 'Active').length)
    setinactive(result.event.members.filter(member => member.status === 'Inactive').length)
    setPending(result.event.members.filter(member => member.status === 'Pending').length)

    setLocation(getAllVenues(result.event.members))

  })


//   fetch('/getSingleSeller',{
//     method:"post",
//     headers:{
//       Authorization: "Bearer " +localStorage.getItem("jwt"),
//       "Content-Type":"application/json"

//     },
//     body:JSON.stringify({
//       id:sellerid
//     })
// }).then(res=>res.json())
// .then(result=>{


//     setSeller(result.seller)
//   // setDataEdit(result.stock)
  

          
// })


  

  },[])


 
  const singlEvent = (Itemid)=>{

    setShow(Itemid)
    fetch(`/getsinglemembership/${Itemid}`,{
      method:"get",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"
  
      }
  }).then(res=>res.json())
  .then(result=>{
  

    setSingleEvent(result.event)
    setParticipants(result.event.members)
    setActive(result.event.members.filter(member => member.status === 'Active').length)
    setinactive(result.event.members.filter(member => member.status === 'Inactive').length)
    setPending(result.event.members.filter(member => member.status === 'Pending').length)
  
    
            
  })


//   fetch(`/getmyparticipants/${Itemid}`,{
//     method:"get",
//     headers:{
//       Authorization: "Bearer " +localStorage.getItem("jwt"),
//       "Content-Type":"application/json"

//     }
// }).then(res=>res.json())
// .then(result=>{

  
//     setParticipants(result.event.members)
//     console.log(result.event.members)
//     // setSingleEarning(result.earning)
  
          
// })


  
  }
    



  const addParticipant = ()=>{

    fetch('/addParticipant',{

        method:"post",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
  
        },
        body: JSON.stringify({
         
          name,phone,code,zone,pic:url,show:show,
        })
        
      }).then(res=>res.json())
      .then(data=>{
        if(data.error){
          console.log("Error")
        }
  
        else{
          
          window.location.reload();
              // history.go(`/employee`)
  
        }
      })
      .catch(err=>{
        console.log(err)
      })
  }
  const postMember = ()=>{

    fetch('/addMemberbyadmin',{

        method:"post",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
  
        },
        body: JSON.stringify({
         
          membership:singleEvent._id,email,name,phone,address
        })
        
      }).then(res=>res.json())
      .then(data=>{
        if(data.error){
          console.log("Error")
        }
  
        else{
          
          window.location.reload();
              // history.go(`/employee`)
  
        }
      })
      .catch(err=>{
        console.log(err)
      })
  }


  const resetWeek = (show)=>{

    const now = new Date();
    const bhutanOffset = 6 * 60; // Bhutan is UTC+6, so offset is 6 hours in minutes
    const localOffset = now.getTimezoneOffset(); 

    fetch('/setvotetozero',{

        method:"put",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
  
        },
        body: JSON.stringify({
         
          totalvotes:weeklyvote,show:show,week:singleEvent.week,date: new Date(now.getTime() + (bhutanOffset - localOffset) * 60000).toLocaleString('en-US', { timeZone: 'Asia/Thimphu' })
        })
        
      }).then(res=>res.json())
      .then(data=>{
        if(data.error){
          console.log("Error")
        }
  
        else{
          console.log("RELOAD")
          window.location.reload();
              // history.go(`/employee`)
  
        }

      })
      .catch(err=>{
        console.log(err)
      })
  }




  const addLive = ()=>{
      // const pattern = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:live\/|watch\?v=)|youtu\.be\/)([^\#\&\?\/]*)/;
      // const match = youtube.match(pattern);


    fetch('/setlive',{

      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
       
        live:youtube,
        id:singleEvent._id
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }
  
  const postEvent = ()=>{

    fetch('/addmembership',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
       
        name,venue,description,price,pic:url,date,category,time,paid
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }
  
  
  
  const revokeMembership = (itemId,status)=>{

    fetch(`/revokemembership/${singleEvent._id}`,{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
       
        memberId:itemId,
        status
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }

  const deleteMember = (itemId)=>{

    fetch(`/deletemembership/${singleEvent._id}/${itemId}`,{

      method:"delete",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
       
        memberId:itemId,
        status
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }



  
  const uploadppt=({target:{files}})=>{

    setProgress(true)

    let data = new FormData()

    for(const file of files){

   data.append('uploadimage', file)
   data.append('name',file.name)
}



       fetch("/uploadimage", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

          setUrl(data.url)
          setProgress(false)

      

       
     })
     .catch(err=>{
         console.log(err)
     })

}
  

const PrintSales = (da) => (

  <Document>
  <Page size="A4" style={styles.page}>
    <View style={styles.header}>
    <Image style={styles.logo} src="/logo.png"
 />

      <Text style={styles.address}>Result for {singleEvent.name}</Text>
      <Text style={{fontSize:10, marginBottom: 3}}>week {singleEvent.week}</Text>
    
    </View>
    <View style={styles.horizontalLine} />

    <View style={styles.invoiceDetails}>
      <View style={styles.itemList}>
        <View style={styles.itemRow}>
          <Text style={styles.label}>Name</Text>
          <Text style={styles.label}>Votes</Text>
        </View>
        <View style={styles.horizontalLine} />

        {participants.map((item, index) => (
          <View>

          <View key={index} style={styles.itemRow}>
            <Text style={styles.itemDescription}>{item.name}</Text>
            <Text style={styles.itemDescription}>{item.votes}</Text>
            {/* <Text style={styles.itemDescription}>{item.qty}</Text> */}
            {/* <Text style={styles.itemDescription}>{item.amount.toFixed(0)}</Text> */}
          </View>
          <View style={styles.horizontalLine} />

          </View>

          
        ))}

      </View>

      <View style={styles.totalRow}>
        <Text style={styles.totalLabel}>Total: {weeklyvote.toFixed(0)}</Text>
        {/* <Text style={styles.totalValue}>{invoice.total}</Text> */}
      </View>
      <View style={styles.horizontalLine} />

    </View>
  </Page>
</Document>
  
);
const PrintHistory = ({week}) => {
  // Filter the participants' weekly votes for the specified week
  const filteredParticipants = participants.map(participant => {
    const weeklyVote = participant.weeklyvotes.find(vote => vote.week === week);
    return {
      name: participant.name,
      votes: weeklyVote ? weeklyVote.votes : 0
    };
  });

  // Calculate the total votes for the specified week
  const totalVotes = filteredParticipants.reduce((acc, participant) => acc + participant.votes, 0);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src="/logo.png" />
          <Text style={styles.address}>Result for {singleEvent.name}</Text>
          <Text style={{ fontSize: 10, marginBottom: 3 }}>week {week}</Text>
        </View>
        <View style={styles.horizontalLine} />

        <View style={styles.invoiceDetails}>
          <View style={styles.itemList}>
            <View style={styles.itemRow}>
              <Text style={styles.label}>Name</Text>
              <Text style={styles.label}>Votes</Text>
            </View>
            <View style={styles.horizontalLine} />

            {filteredParticipants.map((item, index) => (
              <View key={index}>
                <View style={styles.itemRow}>
                  <Text style={styles.itemDescription}>{item.name}</Text>
                  <Text style={styles.itemDescription}>{item.votes}</Text>
                </View>
                <View style={styles.horizontalLine} />
              </View>
            ))}
          </View>

          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>Total: {totalVotes}</Text>
          </View>
          <View style={styles.horizontalLine} />
        </View>
      </Page>
    </Document>
  );
};

function exportToExcel(data) {
  // Convert data to CSV format
  const formattedData = participants.map(({ name, code, votes }) => [name, code, votes]);

  // Add headers to the CSV string
  const csv = [['Name', 'Code', 'Votes']].concat(formattedData.map(row => row.join(','))).join('\n');

  // Create a Blob containing the CSV data
  const blob = new Blob([csv], { type: 'text/csv' });

  // Create a temporary URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${"Week "+singleEvent.week+" Report"}.csv`);

  // Append the link to the DOM and click it programmatically
  document.body.appendChild(link);
  link.click();

  // Clean up: remove the link and revoke the URL
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    padding: 30,
  },
  header: {
    marginBottom: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  address: {
    fontSize: 12,
    marginBottom: 10,
  },
  invoiceDetails: {
    marginBottom: 20,
  },
  label: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  labelfooter: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 8,
    marginTop: 8,

  },
  value: {
    fontSize: 12,
  },
  itemList: {
    marginTop: 2,
  },
  itemRow: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 8,
    marginLeft:8
  },
  itemDescription: {
    width: '20%',
    textAlign:'center',
    fontSize:12

  },
  itemQuantity: {
    width: '15%',
    textAlign: 'left',
  },
  itemAmount: {
    width: '25%',
    textAlign: 'right',
  },
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  totalLabel: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  totalValue: {
    fontSize: 14,
    marginLeft: 10,
  },
  logo: {
    width: 80, 
    height: 80, 
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',  },


  horizontalLine: {
    borderTop: 1,
    borderColor: 'black',
    marginVertical: 4,
    width: '100%',
  },
});








const filtered = (query)=>{
  setSearch("Location")

 setfilteredParticipants( participants.filter(participant => {
      if(query==="All") return true
    return participant.status === query 
  })
)
}


//   console.log("Sinvle"singleEvent.seats)


const [copySuccess, setCopySuccess] = useState(null);

const copyToClipboard = async (linkToCopy) => {
  try {
    await navigator.clipboard.writeText(`https://www.bodhi5entertainment.com/singlemembership/${singleEvent._id}`);
    setCopySuccess(linkToCopy);
  } catch (err) {
    console.error('Unable to copy to clipboard', err);
    setCopySuccess('Copy failed. Please try again.');
  }
};




const getAllVenues = (events) => {
  // Use a Set to store unique venues
  const uniqueVenues = new Set();

  console.log("EEE",events)
  // Iterate through the events and add each venue to the Set
  events.forEach((event) => {
    uniqueVenues.add(event.member.address);
  });

  // Convert the Set back to an array
  return Array.from(uniqueVenues);
};



const fetchPost=(query)=>{




  // fetch('/searchall-members',{

  //   method:"post",
  //   headers:{
  //     Authorization: "Bearer " +localStorage.getItem("jwt"),

  //     "Content-Type":"application/json"

  //   },
  //   body:JSON.stringify({
  //     query,
  //   })

  // }).then(res=> res.json())
  //   .then(results=>{
      
  //     setData(results.events)
  //   })
 setfilteredParticipants(participants.filter(participant => {
    // if (filter === 'All') return true;
    console.log("PPP",participant)
    return  participant.member.address === query;
  }))
  

}


  const Logout = ()=>{
    localStorage.clear()
    dispatch({type:"CLEAR"})
        history(`/login`)
}
 
  return (

    <>



        <main class="relative h-screen overflow-hidden bg-gray-100  rounded-2xl">
        <AdminHeader page="membership"></AdminHeader>

    <div class="flex items-start justify-between">
        
        <div class="flex flex-col w-full pl-0 md:p-4 md:space-y-4">
           
                <div class="h-screen pt-2 pb-24 pl-2 pr-2 overflow-auto md:pt-0 md:pr-0 md:pl-0">
                    <div class="flex flex-col flex-wrap sm:flex-row ">
                       
                        <div class="w-full sm:w-1/2 xl:w-1/3">

    
<div class="mx-0 mb-4 sm:ml-4 xl:mr-4">


<div class="relative overflow-hidden bg-white shadow-lg rounded-2xl w-full">
    <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -top-6 -right-6 md:-right-4"/>
    <div class="px-4 py-1 sm:p-6">
        <dl>
            <dt class="text-sm font-medium leading-5 text-gray-500 truncate">
            <img alt="profil" src={detail?detail.pic:""}class="mx-auto object-cover rounded-full h-20 w-20 "/>
            </dt>
            <dd class="mt-1 text-3xl font-semibold leading-9 text-gray-900">
            {detail?detail.name:""}
            </dd>
            <dd class="font-semibold text-gray-500">
                <span>
                {detail?detail.email:""}

                </span>
            </dd>
            <dd class="font-semibold text-gray-500">
                <span>
                {detail?detail.phone:""}

                </span>
            </dd>
        </dl>
    </div>
    {!membershipactivated?
    <>
      <button type="button" class="py-2 px-4 flex justify-center items-center  bg-red-500 hover:bg-red-700   text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
      onClick={()=>{setAddShow(true)}}
      >
      <svg width="20" height="20" fill="currentColor" class="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
          <path d="M1344 1472q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm256 0q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-224v320q0 40-28 68t-68 28h-1472q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h427q21 56 70.5 92t110.5 36h256q61 0 110.5-36t70.5-92h427q40 0 68 28t28 68zm-325-648q-17 40-59 40h-256v448q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-448h-256q-42 0-59-40-17-39 14-69l448-448q18-19 45-19t45 19l448 448q31 30 14 69z">
          </path>
      </svg>
      Activate Membership
  </button>
   <div class="w-full h-2 bg-blue-200 rounded-full mt-0">
   <div class="w-full h-full text-xs text-center text-white bg-red-500 rounded-full">
   </div>
</div>
</>

    :<>
    
    <button type="button" class="py-2 px-4 flex justify-center items-center  bg-green-500 hover:bg-green-700   text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
      >
   
      Membership Activated
  </button> 
    <div class="w-full h-2 bg-blue-200 rounded-full mt-0">
            <div class="w-full h-full text-xs text-center text-white bg-green-500 rounded-full">
            </div>
        </div>

    </>}
  
 





</div>
 
</div>
{singleEvent?
                       <>
                       
<div class="mx-0 mb-4 sm:ml-4 xl:mr-4">


<div class="relative overflow-hidden bg-white shadow-lg rounded-2xl w-full">
    {/* <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -top-6 -right-6 md:-right-4"/> */}
    <h1 class="text-center"> Membership Events</h1>
          <div className='grid grid-cols-2 gap-4 place-items-center m-4'> 


          <Link to={`/membershipeventsdash/${singleEvent._id}`}>
          

          <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
<svg class="h-8 w-8 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"/>
</svg>
        <p class="text-md text-black  ml-2">
              Events
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-2xl text-left font-bold my-4">
            Manage Events
            
        </p>
   
    </div>
</div>

</Link>


{/* <Link to={`/membershipeventsreport/${singleEvent._id}`}>

          <div class="p-4 bg-white shadow-lg rounded-2xl w-36 dark:bg-gray-800">
    <div class="flex items-center">
        <span class="relative w-4 h-4 p-2 bg-green-500 rounded-full">
            <svg width="20" fill="currentColor" height="20" class="absolute h-2 text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                </path>
            </svg>
        </span>
        <p class="ml-2 text-gray-700 text-md dark:text-gray-50">
             Report
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class=" text-xl font-bold text-left text-gray-800 dark:text-white">
            Check  Report
        </p>
        <div class="relative h-2 bg-gray-200 rounded w-28">
            <div class="absolute top-0 left-0 w-2/3 h-2 bg-green-500 rounded">
            </div>
        </div>
    </div>
</div>
</Link> */}
<Link to={`/membershipeventsreport/${singleEvent._id}`}>

<div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
<svg class="h-8 w-8 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"/>
</svg>
        <p class="text-md text-black  ml-2">
             Report
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-2xl text-left font-bold my-4">
            Track events report
            
        </p>
   
    </div>
</div>
</Link>


<Link to="/qrcode"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
<svg class="h-8 w-8 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"/>
</svg>
        <p class="text-md text-black  ml-2">
            QR Code
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-2xl text-left font-bold my-4">
            Read QR Code
            
        </p>
   
    </div>
</div>

                    </Link>
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white cursor-pointer"
                    onClick={()=>{setAddMember(true)}}
                    >
    <div class="flex items-center">
<svg class="h-8 w-8 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"/>
</svg>
        <p class="text-md text-black  ml-2">
            Add Members
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-2xl text-left font-bold my-4">
            Add  Member
            
        </p>
   
    </div>
</div>


          </div>
 





</div>
 
</div>



                    
                       
                       <div class="w-full ">

                       <div class="mb-4">


                          
                             
                              <div class="p-4 bg-white shadow-lg rounded-2xl ">
                              <div class="flex flex-wrap overflow-hidden">
                                  <div class="w-full rounded shadow-sm">
                                      <div class="flex items-center justify-between mb-4">
                                          <div class="text-xl font-bold text-left text-black ">
                                              {singleEvent?singleEvent.name:""} 
                                          </div>
                                          <div class="flex space-x-4">
                                              <button class="p-2 text-white bg-purple-500 rounded-lg">
                                              {/* Week {singleEvent?singleEvent.week:""}  */}

                                              </button>
                                              
                                          </div>
                                          {/* PDF: */}
                                          {/* <PDFDownloadLink document={<PrintSales test={"TESt sf"}/>} fileName="Result.pdf">
 {({ blob, url, loading, error }) =>
   loading ? 'Loading report...' : <><svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>
    </>
 }
</PDFDownloadLink> */}



{/* Excel: */}
                                   {/* <svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                   onClick={()=>{exportToExcel()}}
                                   >   <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg> */}

                                      </div>

                                  
                                      <div class="-mx-2">
                                       

     


<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 my-4">

<div class="p-4 bg-white shadow-lg rounded-2xl ">
<div class="flex items-center">
  <span class="relative p-1 bg-purple-200 rounded-xl">
  <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
</svg>

  </span>
  <p class="ml-2 text-black text-md ">
      Total Members
  </p>

</div>



{/* <button type="button" class="mt-2 py-2 px-4 flex justify-center items-center  bg-purple-600 hover:bg-purple-700   text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{setAddStock(true)}}
>
<svg class="h-6 w-6 text-gray-100 mr-2"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="9" cy="7" r="4" />  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />  <path d="M16 11h6m-3 -3v6" /></svg>
Add Participant
</button> */}
<h2 className='text-center text-bold text-3xl' >

{singleEvent.members.length}

</h2>

</div>
<div class="p-4 bg-white shadow-lg rounded-2xl cursor-pointer "
onClick={()=>{

  filtered("Active")
  setFilter('Active')
  const element = document.getElementById("listtable");
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}}
>
<div class="flex items-center">
  <span class="relative p-1 bg-purple-200 rounded-xl">
  <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
</svg>

  </span>
  <p class="ml-2 text-black text-md ">
      Active Members
  </p>

</div>


<h2 className='text-center text-bold text-3xl' >

{active}

</h2>
{/* <button type="button" class="py-2 px-4 mb-2 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{if(window.confirm('Are you sure you want to start new Week?')) resetWeek(singleEvent._id)}}                           

>
<svg class="h-8 w-8 text-white mr-2"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" /></svg>
Start Voting for Next Week
</button> */}

</div>

<div class="p-4 bg-white shadow-lg rounded-2xl cursor-pointer "

onClick={()=>{

  filtered("Inactive")
  setFilter('Inactive')
  const element = document.getElementById("listtable");
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}}
>
<div class="flex items-center">
  <span class="relative p-1 bg-purple-200 rounded-xl">
  <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
</svg>

  </span>
  <p class="ml-2 text-black text-md ">
      Inactive Members
  </p>

</div>


<h2 className='text-center text-bold text-3xl' >

{inactive}

</h2>
{/* <button type="button" class="py-2 px-4 mb-2 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{if(window.confirm('Are you sure you want to start new Week?')) resetWeek(singleEvent._id)}}                           

>
<svg class="h-8 w-8 text-white mr-2"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" /></svg>
Start Voting for Next Week
</button> */}

</div>
<div class="p-4 bg-white shadow-lg rounded-2xl cursor-pointer"

onClick={()=>{

  filtered("Pending")
  setFilter('Pending')
  const element = document.getElementById("listtable");
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}}
>
<div class="flex items-center">
  <span class="relative p-1 bg-purple-200 rounded-xl">
  <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
</svg>

  </span>
  <p class="ml-2 text-black text-md ">
      Pending Members
  </p>

</div>


<h2 className='text-center text-bold text-3xl' >

{pending}

</h2>
{/* <button type="button" class="py-2 px-4 mb-2 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{if(window.confirm('Are you sure you want to start new Week?')) resetWeek(singleEvent._id)}}                           

>
<svg class="h-8 w-8 text-white mr-2"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" /></svg>
Start Voting for Next Week
</button> */}

</div>
<div class="p-4 bg-white shadow-lg rounded-2xl ">
<div class="flex items-center">
  <span class="relative p-1 bg-purple-200 rounded-xl">
  <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
</svg>

  </span>
  <p class="ml-2 text-black text-md ">
      Share Link
  </p>

</div>


<svg class={`h-8 w-8 ${copySuccess!==null?"text-green-500":"text-gray-600"}`}  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"

onClick={()=>{copyToClipboard("j")}}
>  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="8" y="8" width="12" height="12" rx="2" />  <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" /></svg>
{/* <button type="button" class="py-2 px-4 mb-2 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{if(window.confirm('Are you sure you want to start new Week?')) resetWeek(singleEvent._id)}}                           

>
<svg class="h-8 w-8 text-white mr-2"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" /></svg>
Start Voting for Next Week
</button> */}

</div>



{/* <div class="p-4 bg-white shadow-lg rounded-2xl cursor-pointer ">
  <Link to={`/membershipeventsdash/${singleEvent._id}`}>
<div class="flex items-center">
  <span class="relative p-1 bg-purple-200 rounded-xl">
  <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
</svg>

  </span>
  <p class="ml-2 text-black text-md ">
      Membership Events
  </p>

</div>


</Link>


</div> */}







</div>



                                      </div>
                                  </div>
                              </div>
                          </div>

                             




    
                          

                           
                       </div>
                       
                  
                  
                  
                  
                   </div>
                   </>


:<></>}



                          
                        </div>
                        {singleEvent?

<>
                        
<div id="listtable" className="container max-w-3xl px-4 mx-auto sm:px-8">
      <div className="grid md:grid-cols-6 grid-cols-3 gap-2">
        <span
          className={`px-4 py-2 text-center rounded-full text-indigo-500 border border-indigo-500 ${filter === 'All' ? 'bg-indigo-500 text-white' : ''}`}
          onClick={() => {setFilter('All');filtered("All")}}
        >
          All
        </span>
        <span
          className={`px-4 py-2 text-center rounded-full text-indigo-500 border border-indigo-500 ${filter === 'Pending' ? 'bg-indigo-500 text-white' : ''}`}
          onClick={() => {setFilter('Pending');filtered("Pending")}}
        >
          Pending
        </span>
        <span
          className={`px-4 py-2 text-center rounded-full text-indigo-500 border border-indigo-500 ${filter === 'Active' ? 'bg-indigo-500 text-white' : ''}`}
          onClick={() => {setFilter('Active'); filtered("Active")}}
        >
          Active
        </span>
        <span
          className={`px-4 py-2 text-center rounded-full text-indigo-500 border border-indigo-500 ${filter === 'Inactive' ? 'bg-indigo-500 text-white' : ''}`}
          onClick={() => {setFilter('Inactive'); filtered("Inactive")}}
        >
          Inactive
        </span>
        <select id="animals" class="px-4 py-2 flex items-center text-base rounded-full text-indigo-500 border border-indigo-500 undefined" name="animals"
    
    value= {search} onChange={(e)=>fetchPost(e.target.value)}

    
    >
        <option class="shadow border select-none cursor-pointer bg-white  rounded-md flex flex-1 items-center p-4" value="Location">
            Location
        </option>
        {location.length>0? location.map(item=>{
return(  <option value={item}>
  {item}
</option>)
        }):<></>}
     
     
        
       
    </select>

      </div>

      <div className="py-2">
        <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
          <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                    Member
                  </th>
                  <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                    Email
                  </th>
                  <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                    Phone
                  </th>
                  <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                    Address
                  </th>
                  <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                    Status
                  </th>
                  <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                    Del
                  </th>
                </tr>
              </thead>
              <tbody >
                {filteredParticipants.length > 0 ? (
                  filteredParticipants.map(participant => (
                    <tr key={participant._id}>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <a href="#" className="relative block">
                              <img
                                alt="profil"
                                src={participant.pic}
                                className="mx-auto object-cover rounded-full h-10 w-10"
                              />
                            </a>
                          </div>
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">{participant.member.name}</p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                        <p className="text-gray-900 whitespace-no-wrap">{participant.member.email}</p>
                      </td>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                        <p className="text-gray-900 whitespace-no-wrap">{participant.member.phone}</p>
                      </td>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                        <p className="text-gray-900 whitespace-no-wrap">{participant.member.address}</p>
                      </td>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                      {participant.status === 'Active' && <span className="text-green-500"    
                                                   onClick={()=>{if(window.confirm('Are you sure you want to Revoke Membership?'))   revokeMembership(participant._id,"Inactive")}}                           >Active</span>}
                        {participant.status === 'Pending' && (
                          <button
                            type="button"
                            className=" w-full px-4 py-2 text-base bg-red-500 border rounded-lg text-white hover:bg-red-700"
                            onClick={()=>{if(window.confirm('Are you sure you want to Reactivate Membership?'))   revokeMembership(participant._id,"Active")}}                           

                          >
                            Activate
                          </button>
                        )}
                        {participant.status === 'Inactive' && <span className="text-red-500"    
                                                   onClick={()=>{if(window.confirm('Are you sure you want to Activate Membership?'))   revokeMembership(participant._id,"Active")}}                           >Inactive</span>}
                      </td>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                      <svg class="h-6 w-6 text-red-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor"
                      
                      onClick={()=>{if(window.confirm('Are you sure you want to Delete Membership?'))                        deleteMember(participant._id)
                      }}                           


                      >



  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
</svg>

                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-center">
                      No participants found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

                        {/* <div class="mx-0 mb-4 sm:ml-4 xl:mr-4">
                                <div class="w-full p-4 bg-white shadow-lg rounded-2xl mb-2 ">
                                    <p class="font-bold text-black text-md ">
                                        Active Members 


                                    </p>


     



                            {participants?participants.filter(participant => participant.status == 'Active').map(participant=>{

                                // console.log("PAR",participant.status)
                                return(
                                    <div class="p-4 bg-white shadow-lg rounded-2xl w-80 dark:bg-gray-800 m-2">
                                    <div class="flex flex-row items-start gap-4">
                                        <img src={participant.member.pic} class="rounded-lg w-16 h-16"/>
                                        <div class="flex flex-col justify-between w-full ">
                                            <div>
                                                <p class="text-xl font-medium text-gray-800 dark:text-white">
                                                    {participant.member.name}
                                                </p>
                                                <p class="text-xs text-gray-400">
                                                    {participant.member.phone}
                                                </p>
                                                <p class="text-xs text-gray-400">
                                                    {participant.member.email}
                                                </p>
                                            </div>
                                        
                                        </div>
                                    </div>
                                    <div class="flex items-center justify-between gap-4 mt-6">
                                        <button type="button" class="w-full px-4 py-2 text-base bg-red-500 border rounded-lg text-white hover:bg-red-700 "
                                        
                                        onClick={()=>{if(window.confirm('Are you sure you want to Revoke Membership?'))   revokeMembership(participant._id,"Inactive")}}                           

                                        >
                                            Revoke Membership
                                        </button>
                                       
                                    </div>
                                </div>
                                
                                
                                )
                            }):<></>} 

          
                                </div>

                                
                            </div>

                        <div class="mx-0 mb-4 sm:ml-4 xl:mr-4">
                                <div class="w-full p-4 bg-white shadow-lg rounded-2xl mb-2 ">
                                    <p class="font-bold text-black text-md ">
                                        Inactive Members 


                                    </p>


     



                            {participants?participants.filter(participant => participant.status == 'Inactive').map(participant=>{

                                // console.log("PAR",participant.status)
                                return(
                                  <div class="p-4 bg-white shadow-lg rounded-2xl w-80 dark:bg-gray-800 m-2">
                                  <div class="flex flex-row items-start gap-4">
                                      <img src={participant.member.pic} class="rounded-lg w-16 h-16"/>
                                      <div class="flex flex-col justify-between w-full ">
                                          <div>
                                              <p class="text-xl font-medium text-gray-800 dark:text-white">
                                                  {participant.member.name}
                                              </p>
                                              <p class="text-xs text-gray-400">
                                                  {participant.member.phone}
                                              </p>
                                              <p class="text-xs text-gray-400">
                                                  {participant.member.email}
                                              </p>
                                          </div>
                                      
                                      </div>
                                  </div>
                                  <div class="flex items-center justify-between gap-4 mt-6">
                                      <button type="button" class="w-full px-4 py-2 text-base bg-red-500 border rounded-lg text-white hover:bg-red-700 "
                                      
                                      onClick={()=>{if(window.confirm('Are you sure you want to Activate?'))   revokeMembership(participant._id,"Active")}}                           

                                      >
                                          Activate Membership
                                      </button>
                                     
                                  </div>
                              </div>
                              
                                
                                )
                            }):<></>} 

          
                                </div>

                                
                            </div>
                        <div class="mx-0 mb-4 sm:ml-4 xl:mr-4">
                                <div class="w-full p-4 bg-white shadow-lg rounded-2xl mb-2 ">
                                    <p class="font-bold text-black text-md ">
                                        Pending Members 


                                    </p>


     



                            {participants?participants.filter(participant => participant.status == 'Pending').map(participant=>{

                                // console.log("PAR",participant.status)
                                return(
                                  <div class="p-4 bg-white shadow-lg rounded-2xl w-80 dark:bg-gray-800 m-2">
                                  <div class="flex flex-row items-start gap-4">
                                      <img src={participant.member.pic} class="rounded-lg w-16 h-16"/>
                                      <div class="flex flex-col justify-between w-full ">
                                          <div>
                                              <p class="text-xl font-medium text-gray-800 dark:text-white">
                                                  {participant.member.name}
                                              </p>
                                              <p class="text-xs text-gray-400">
                                                  {participant.member.phone}
                                              </p>
                                              <p class="text-xs text-gray-400">
                                                  {participant.member.email}
                                              </p>
                                          </div>
                                      
                                      </div>
                                  </div>
                                  <div class="flex items-center justify-between gap-4 mt-6">
                                      <button type="button" class="w-full px-4 py-2 text-base bg-red-500 border rounded-lg text-white hover:bg-red-700 "
                                      
                                      onClick={()=>{if(window.confirm('Are you sure you want to Activate?'))   revokeMembership(participant._id,"Active")}}                           

                                      >
                                          Activate Membership
                                      </button>
                                     
                                  </div>
                              </div>
                              
                                
                                )
                            }):<></>} 

          
                                </div>

                                
                            </div>
 */}

{/* <div class="container max-w-3xl px-4 mx-auto sm:px-8">
    <div class="py-8">
        <div class="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
            <div class="inline-block min-w-full overflow-hidden rounded-lg shadow">
                <table class="min-w-full leading-normal">
                    <thead>
                        <tr>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Participant
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Phone
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Email
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                      
                    {participants?participants.filter(participant => participant.status == 'Inactive').map(participant=>{

                      inactive+=1

return(
  <tr>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0">
                                        <a href="#" class="relative block">
                                            <img alt="profil" src={participant.pic} class="mx-auto object-cover rounded-full h-10 w-10 "/>
                                        </a>
                                    </div>
                                    <div class="ml-3">
                                        <p class="text-gray-900 whitespace-no-wrap">
                                            {participant.member.name}
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <p class="text-gray-900 whitespace-no-wrap">
                                    {participant.member.phone}
                                </p>
                            </td>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <p class="text-gray-900 whitespace-no-wrap">
                                   {participant.member.email}
                                </p>
                            </td>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                            <button type="button" class="w-full px-4 py-2 text-base bg-red-500 border rounded-lg text-white hover:bg-red-700 "
                                        
                                        onClick={()=>{if(window.confirm('Are you sure you want to Eliminate?'))   revokeMembership(participant._id)}}                           

                                        >
                                            Inactive
                                        </button>
                            </td>
                        </tr>
                      


)
}):<></>}

                      
                     
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div> */}


                            </>:<></>}

                            
                    </div>
                </div>

                {
addStock? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setAddStock(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           



<div class="flex w-full max-w-sm content-center items-center ">
    <div class="w-full max-w-2xl px-5 py-6 m-auto  bg-white rounded-lg shadow ">
       
    <div class="grid max-w-xl grid-cols-2 gap-4 m-auto">

       
        <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Name</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Name"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            >

            </input>
          </div>
        
          <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Phone</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Phone No."
            value={phone}
            onChange={(e)=>setPhone(e.target.value)}
            >

            </input>
          </div>
          </div>
          <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Code</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Code"
            value={code}
            onChange={(e)=>setCode(e.target.value)}
            >

            </input>
          </div>
      
                        
         
          <div className="py-1">
  <span className="px-1 text-sm text-gray-600">Dzongkhag</span>
    <select  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
  bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
  
    placeholder="Zone"
    value={zone}
    onChange={(e)=>setZone(e.target.value)}
    >

    <option value="Bumthang">Bumthang</option>  
    <option value="Chhukha">Chhukha</option>  
    <option value="Dagana">Dagana</option>  
    <option value="Gasa">Gasa</option>  
    <option value="Haa">Haa</option>  
    <option value="Lhuentse">Lhuentse</option>  
    <option value="Mongar">Mongar</option>  
    <option value="Paro">Paro</option>  
    <option value="Pemagatshel">Pemagatshel</option>  
    <option value="Punakha">Punakha</option>  
    <option value="Samdrup Jongkhar">Samdrup Jongkhar</option>  
    <option value="Samtse">Samtse</option>  
    <option value="Sarpang">Sarpang</option>  
    <option value="Thimphu">Thimphu</option>  
    <option value="Trashigang">Trashigang</option>  
    <option value="Trashiyangtse">Trashiyangtse</option>  
    <option value="Trongsa">Trongsa</option>  
    <option value="Tsirang">Tsirang</option>  
    <option value="Wangdue Phodrang">Wangdue Phodrang</option>  
    <option value="Zhemgang">Zhemgang</option>  



    
    {/* <option>Hongkong-Market</option>
<option>Vegitable-Market</option> */}
    
    </select>
       

            {/* <div className="py-1">
            <span className="px-1 text-sm text-gray-600">Zone</span>
              <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
            bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
            
              placeholder="CID"
              value={zone}
              onChange={(e)=>setZone(e.target.value)}
              >

              </input>
            </div> */}
            </div>

       
       
        
       
        
          <div className="col-span-4 ">
                       <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
        <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
        </svg>
        <span className="mt-2 text-base leading-normal">Upload</span>
        <input type='file' className="hidden" 
        onChange={uploadppt}
        />
        
    </label>



    {progress?         <span className=" text-base leading-normal"> Uploading...</span>:   <span className=" text-base leading-normal"> Upload Photo </span>
         }
                <input type='file' className="hidden"  required
                onChange={uploadppt}
                />
                
        
            {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}
   

{url?<img className=" w-28 h-28  rounded-lgflex items-center " src={url} ></img>:null
}
    
                               </div> 

              <br/>
              <div class="col-span-2 text-right">
                        <button  class="py-2 px-4  bg-yellow-300 hover:bg-orange-400 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                        

                        onClick={()=>addParticipant()}
                        >
                            Add Participants
                        </button>


                    </div>
               
                
                </div>
        </div> 
         
 




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}
                {
live? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setLive(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           



<div class="flex w-full max-w-sm content-center items-center ">
    <div class="w-full max-w-2xl px-5 py-6 m-auto  bg-white rounded-lg shadow ">
       
  
          <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Paste Link (Youtube, Facebook, Tiktok)</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Link"
            value={youtube}
            onChange={(e)=>setYoutube(e.target.value)}
            >

            </input>
          </div>
      
                        
         
          

       
       
        
       
        
              <br/>
              <div class="col-span-2 text-right">
                        <button  class="py-2 px-4  bg-yellow-300 hover:bg-orange-400 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                        

                        onClick={()=>addLive()}
                        >
                            Add Live Streaming
                        </button>


                    </div>
               
                
                </div>
        </div> 
         
 




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}
                
                {
addShow? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setAddShow(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           



<div class="flex w-full max-w-sm content-center items-center ">
    <div class="w-full max-w-2xl px-5  m-auto  bg-white rounded-lg shadow ">
    
    <div>


<div className="">
<span className="px-1 text-sm text-gray-600">Name</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Name"
value={name}
onChange={(e)=>setName(e.target.value)}
>

</input>
</div>







<div class="grid max-w-xl grid-cols-2 gap-4 ">








</div>


<div>
      <label className="flex items-center space-x-2">
        <input
          type="checkbox"
          // checked={isChecked}
          onChange={()=>setPaid(!paid)}
          className="form-checkbox text-indigo-500 h-5 w-5"
        />
        <span className="text-gray-700">Is it Paid Membership?</span>
      </label>
    </div>


{paid?<>
  <div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Price</span>
<input  type="number" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Price"
value={price}
onChange={(e)=>setPrice(e.target.value)}
>

</input>
</div>



</div>

</>:<></>}



<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="">

<label class="text-gray-700" for="animals">
    Category 
    <select id="animals" class="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" name="animals"
    
    value={category}
    onChange={(e)=>setCategory(e.target.value)}
    
    >
        <option value="">
            Select an option
        </option>
        <option value="Dance">
            Association
        </option>
        <option value="Religious">
            Religious
        </option>
        <option value="Student">
            Student
        </option>
       
     
        <option value="Charity">
            Charity
        </option>
       
     
        <option value="Community">
        Community
        </option>
       
     
        <option value=" ">
            Others
        </option>
       
     
        
       
    </select>
</label>

</div>


{category==" "?
  <div className="">

<label class="text-gray-700" for="animals">
    Enter New Category 
   
</label>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Enter New Category"
value={category}
onChange={(e)=>setCategory(e.target.value)}
>

</input>
</div>
:<></>}






</div>



<div className="py-1">
<span className="px-1 text-sm text-gray-600">Description</span>
<textarea  type="text" className="space-y-10 text-md block px-3 py-2 rounded-lg w-full h-32
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Event Description"
value={description}
onChange={(e)=>setDescription(e.target.value)}
>

</textarea>
</div>


<div className="col-span-4 w-full mb-5 ">
                        <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
         <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
             <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
         </svg>
        <span className="mt-2 text-base leading-normal">Upload</span>
        <input type='file' className="hidden" 
        onChange={uploadppt}
        />
        
    </label>
                                   </div>
 
     
     
                                   {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}
   

{url?<img className=" w-28 h-28  rounded-lgflex items-center " src={url} ></img>:null
}


  <div className="w-full px-3 mb-5">

    
  
      <button className="block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
                  onClick={() => {setAddShow(false);postEvent()}}

      > Add Membership</button>
      <button className="block w-full max-w-xs mx-auto bg-red-300 hover:bg-red-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold mt-4"
                  onClick={() => {setAddShow(false)}}

      > Cancel</button>
                  


    </div>



    
</div>
       
    
    </div>
        </div> 
         
 




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}


{
addMember? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setAddMember(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           



<div class="flex w-full max-w-sm content-center items-center ">
    <div class="w-full max-w-2xl px-5  m-auto  bg-white rounded-lg shadow dark:bg-gray-800">
    
    <div>


<div className="">
<span className="px-1 text-sm text-gray-600">Name</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Name"
value={name}
onChange={(e)=>setName(e.target.value)}
>

</input>
</div>

<div className="">
<span className="px-1 text-sm text-gray-600">Email</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Price for single vote "
value={email}
onChange={(e)=>setEmail(e.target.value)}
>

</input>
</div>












<div className="">
<span className="px-1 text-sm text-gray-600">Phone</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Phone No."
value={phone}
onChange={(e)=>setPhone(e.target.value)}
>

</input>
</div>










<div className="">
<span className="px-1 text-sm text-gray-600">Address</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Address"
value={address}
onChange={(e)=>setAddress(e.target.value)}
>

</input>
</div>









     
                                   {/* {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}
   

{url?<img className=" w-28 h-28  rounded-lgflex items-center " src={url} ></img>:null
} */}


  <div className="w-full px-3 mb-5">

    
  
      <button className="block w-full mt-2 max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
                  onClick={() => {setAddMember(false);postMember()}}

      > Add Member</button>
      <button className="block w-full max-w-xs mx-auto bg-red-300 hover:bg-red-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold mt-4"
                  onClick={() => {setAddMember(false)}}

      > Cancel</button>
                  


    </div>



    
</div>
       
    
    </div>
        </div> 
         
 




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}
                

            </div>
            
        </div>
        
    </main>


    

    </>
    
  );
}
 
export default MembershipDash;